import Client from "./clients/SecureWarehouseClient";

const resource = "/warehouse";

export default {
    async createPickingOrder(warehouseId, pickingOrder) {
        if(warehouseId == null) {
            return Promise.resolve(pickingOrder);
        }
        return Client.post(`${resource}/${warehouseId}/picking`,
            pickingOrder)
            .then((response) => {
                return response.data;
            })
            .catch(() => {

            })
    },
    async addPickingOrder(warehouseId, pickingOrder) {
        if (warehouseId == null) {
            return Promise.resolve([]);
        }
        return Client.post(
            `${resource}/${warehouseId}/picking/pickingOrderProposal`,
            pickingOrder
        )
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                //return {};
            });
    },
    async cancelPickingOrder(warehouseId, id) {
        if (warehouseId === null) {
            return Promise.resolve([]);
        }

        return Client.post(
            `${resource}/${warehouseId}/picking/${id}/cancel`
        )
    },
    async completePickingOrder(warehouseId, id) {
        if (warehouseId === null) {
            return Promise.resolve([]);
        }
        console.log(warehouseId);
        console.log(id);

        return Client.post(
            `${resource}/${warehouseId}/picking/${id}/complete?`
        )
    },
    async updatePickingOrder(warehouseId, id, pickingOrder) {
        if (warehouseId === null) {
            return Promise.resolve([]);
        }
        return Client.post(`${resource}/${warehouseId}/picking/${id}`, pickingOrder);
    },
    async getPickingOrders(warehouseId, userId, orderState) {
        if(warehouseId === null || userId === null) {
            return Promise.resolve([]);
        }
        return Client.get(`${resource}/${warehouseId}/picking?userId=${userId}&orderState=${orderState.join()}`);

    }
};
